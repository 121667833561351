export function formatNumber(num: Number) {
  return (num || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function removeDotZero(value: string) {
  return value.replace(/\.0/gi, "");
}

export function formatKMBT(y: number) {
  if (y >= 1000000000000) {
    return removeDotZero((y / 1000000000000).toFixed(1) + "T");
  } else if (y >= 1000000000) {
    return removeDotZero((y / 1000000000).toFixed(1) + "B");
  } else if (y >= 1000000) {
    return removeDotZero((y / 1000000).toFixed(1) + "M");
  } else if (y >= 1000) {
    return removeDotZero((y / 1000).toFixed(1) + "K");
  } else if (y < 1 && y > 0) {
    return removeDotZero(y.toFixed(2));
  } else {
    return y;
  }
}
